&.accommodation-search-book {

    .page-title {
        text-align: center
    }

    .content-section {
        padding-top: 0;

        .container {
            @extend .container-wide;
            padding: 0 15px;
        }
    }
}

&.accommodation-detail {

    .header {
        box-shadow: 3px 0 6px rgba($black, .29);
    }

    .lead-section {
        .container {
            max-width: 1070px;
        }
    }

    .title {
        h1 {
            margin-bottom: 0;
        }
    }

    .info {
        .list {
            gap: 10px;
            margin-top: .5rem;

            .list-item {
                flex-direction: row-reverse;
    
                &.list-item-with-title {
                    padding: 0;
                }

                &:hover {
                    background-color: unset;
                    color: $primary;
                }
    
                .title {
                    font-style: italic;
                }
            }
        }
        
    }

    .owl-carousel .owl-nav {
        .owl-prev,
        .owl-next {
            font-size: 20px;
        }
    }

    .item-label {
        background-color: $olive;
        color: $olive-light;

        &::after {
            border-left-color: $olive;
        }
    }
}