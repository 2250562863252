// mini-sab
.mini-sab {
	background: $light;
	text-align: center;
	padding-top: 2rem;
	padding-bottom: 4rem;

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			padding: 0 50px;
		}

		.page-block.title {
			margin-top: 2rem;
		}

		.mini-search-book {
			margin-top: 2rem;
		}
	}
}
