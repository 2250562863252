// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	border: 0.25rem solid $light;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh;
	max-height: 270px;
	border: 0.875rem solid $light;

	@include media-breakpoint-up(sm) {
		max-height: 540px;
	}

	@include media-breakpoint-up(lg) {
		max-height: 700px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 125vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@include media-breakpoint-up(xl) {
				height: 105vw;
			}
		}
	}
}
