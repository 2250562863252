.card {
	&.card-overlay {
		border-radius: 0;

		.card-image,
		.card-image img {
			aspect-ratio: 1 / 1.51;
		}

		.card-img-overlay {
			border-radius: 0;
			display: flex;
			align-items: flex-end;
			background-color: unset;
			background-image: linear-gradient(
				rgba($black, 0) 0%,
				rgba($black, 0) 45.5%,
				rgba($black, 0.5) 100%
			);
		}

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-title {
				order: 2;
				font-size: $h5-font-size;
				margin-bottom: 0;
			}

			.card-subtitle {
				order: 1;
				font-size: $h2-font-size;
				color: $olive-light;
				margin-bottom: 0;
			}

			.card-description {
				display: none;
			}
		}
	}

	&:not(.card-overlay) {
		background-color: $light;
		border: none;
		border-radius: unset;

		.card-image {
			.card-image-caption {
				top: 0;
				display: flex;
				align-content: flex-end;
				flex-wrap: wrap;
				background-color: unset;
				background-image: linear-gradient(
					rgba($black, 0) 0%,
					rgba($black, 0) 45.5%,
					rgba($black, 0.5) 100%
				);
				padding: 20px;

				.card-image-label {
					flex: 0 0 100%;
					padding: 0;
					color: $olive-light;
					font-weight: 700;
					font-style: italic;
					font-size: $small-font-size;
				}
			}
		}

		.card-title {
			flex: 0 0 100%;
			color: $white;
			font-size: $h5-font-size;
			margin: 5px 0 0;
		}

		.card-body {
			padding: 1.5rem 1.875rem;

			&.no-content {
				@extend .d-none;

			}
		}

		.card-caption-header {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1rem;
		}

		.card-subtitle {
			font-weight: 400;
			font-style: italic;
			font-size: $small-font-size;
			margin: 0;
		}

		.list-icons {
			color: $olive;
			display: flex;
			gap: 1rem;
			margin: 0;

			.list-item {
				padding-left: 0;
				padding-right: 1.5rem;
				cursor: pointer;

				.icon {
					font-size: 15px;
					right: 0;
					left: auto;

					i {
						font-weight: 300;
					}
				}

				.title {
					font-weight: 400;
					font-style: italic;
					font-size: $small-font-size;
				}

				&:hover {
					.hover-label {
						bottom: calc(100% + 1em);
						z-index: 1;
						visibility: visible;
						opacity: 1;
					}
				}

				.hover-label {
					position: absolute;
					bottom: 100%;
					left: 50%;
					width: max-content;
					transform: translateX(-50%);
					background-color: $black;
					color: #fff;
					padding: 4px 15px;
					border-radius: 4px;
					filter: drop-shadow(2px 2px 2px rgba(#000, 0.4));
					z-index: -1;
					visibility: hidden;
					opacity: 0;
					transition: 0.2s;
					line-height: 1.5;
					font-size: $small-font-size;

					@include media-breakpoint-down(xl) {
						max-width: 100px;
						padding: 4px 10px;
						text-overflow: ellipsis;
						overflow-x: clip;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						bottom: -8px;
						left: 50%;
						transform: translateX(-50%);
						border-left: 10px solid transparent;
						border-top: 10px solid $black;
						border-right: 10px solid transparent;
					}
				}
			}
		}

		.card-description {
			display: flex;
			flex-direction: column-reverse;

			&-content {
				font-weight: 300;
				font-size: $small-font-size;
			}

			.list-icons {
				display: none;
			}
		}
	}
}


