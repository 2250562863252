// btn
.btn {
	padding: 0.625rem 1.125rem;
	box-shadow: $shadow;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 2px;
	display: inline-flex;
	line-height: normal;

	// btn-primary
	&.btn-primary {
		color: $white !important;

		// &:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
			color: $white !important;
		}
	}

	// btn-secondary
	&.btn-secondary {
		color: $olive-light !important;

		&:hover {
			color: $olive-light;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $secondary !important;

		&:hover {
			color: $white !important;
		}
	}
}

// card-btn
.card-btn {
	color: $link-color;
	text-decoration: $link-decoration;
	font-size: $small-font-size;
	font-weight: 300;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1.5rem;

	&::before {
		content: "\f137";
		position: absolute;
		left: 0;
		font-weight: 300;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

ul.book-button {
	li {
		a {
			@extend .btn;
			@extend .btn-secondary;
		}
	}
}