// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(-50%); }
}

ul.home-slider {
    animation: scroll 20s linear infinite;
    flex-wrap: nowrap !important;
    width: max-content;

    li {
        white-space: nowrap;
        margin-right: 4rem;
    }

    i {
        color: $secondary;
    }

    .list-item-title {
        font-size: $small-font-size;
        margin-left: 5px;
    }
}