// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-wide {
		max-width: 1290px;
		position: relative;
		
		@include media-breakpoint-up(lg) {
			padding-left: 60px;
			padding-right: 60px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// link
a {
	font-weight: 300;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.2s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.subtitle {
	font-style: italic;

	> * {
		font-weight: 400;
		margin-bottom: 0;
		font-size: $small-font-size;
	}
}

// default-card
// .default-card {}

// section bg
.section-bg {
	position: relative;
	overflow: hidden;

	.container {
		z-index: 1;
		position: relative;
	}

	&::after {
		content: url(/images/section-bg.svg);
		position: absolute;
		top: 2rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
	}
}
