&.home {
    .content-section {
        .container-two-columns {
            > .container-holder {
                align-items: center;

                .column {
                    &.one {
                        @extend .col-lg-4;
                        @extend .pr-3;
                        text-align: center
                    }

                    &.two {
                        @extend .col-lg-8;
                        @extend .pl-3;
                    }
                }
            }
        }
    }
}