.contentblock {
	&.collection.masonry {
		.card-columns {
			@include media-breakpoint-up(lg) {
				column-count: 4;
			}

			@include media-breakpoint-up(xl) {
				column-gap: 60px;
			}

			.item {
				&:nth-child(even) {
					@include media-breakpoint-up(lg) {
						padding-top: 25%;
					}
				}

				&:nth-child(3) {
					@include media-breakpoint-between(sm, md) {
						padding-top: 50%;
					}
				}
			}
		}
	}

	&.collection.grid {
		.grid-items {
			@include media-breakpoint-up(lg) {
				margin: 0 -30px -60px -30px;
			}

			.item {
				@include media-breakpoint-up(lg) {
					padding: 0 30px;
					margin-bottom: 60px;
					align-self: flex-start;
				}
			}
		}

		&.accommodation-collection {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						&:nth-child(odd) {
							margin-top: 3rem;
						}
					}
				}
			}
		}
	}

	&.collection.slider {
		.card {
			.card-image, 
			.card-image img {
				aspect-ratio: unset;
			}
		}
	}
}
