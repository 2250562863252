// header
.header {
	font-size: .875rem;
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		@extend .pl-lg-4;
		@extend .pr-lg-4;

		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: $black;
		}
	}

	// logo
	.logo {
		margin: 14px auto 14px 0;

		@include media-breakpoint-down(sm) {
			width: 165px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			.nav-link {
				padding-right: .625rem;
				padding-left: .625rem;
				color: $black;

				&[aria-expanded=true],
				&:hover {
					color: $primary;
				}

			}
		}

		@include media-breakpoint-down(lg) {
			order: 1;
		}

		@include media-breakpoint-up(xl) {
			border-right: 1px solid $black;
			justify-content: flex-end;
			margin-right: 1.125rem;
			padding-right: .625rem;
		}
	}

	// site-switcher
	.site-switcher {
		margin-right: 1rem;
		.navbar-nav {
			.nav-item {
				.nav-link {
					box-shadow: none !important;
					text-transform: uppercase;
					color: $black;
					
					&:hover {
						color: $primary;
					}

					&::after {
						display: none;
					}
				}
			}
		}
	}
}
