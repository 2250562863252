.timeslot-item-day {
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 1px;
	.timeslot-item-day-times-holder,
	.timeslot-item-day-title-holder {
		flex: unset !important;
		max-width: 50% !important;
		width: 50%;
		padding: 15px;
	}
}
