// footer
.footer {

    .container-fluid {
        @extend .pl-lg-5;
		@extend .pr-lg-5;
    }

    .footer-outro {
        padding: 1.5rem 0;

        .container-one-column {
            .container-holder {
                > .column {
                    flex-direction: row;
                    align-items: flex-end;
                    gap: .5rem;
                }
            }
        }

        .image {
            flex: 0 0 100px;
        }

        .footer-text {
            max-width: 660px;
            font-size: $small-font-size;

            p {
                margin: 0;
            }
        }
    }

    .footer-sitelinks {
        background-color: $grey;
        padding: 6vh 0;
        font-size: $small-font-size;

        * {
            color: $white;
        }

        .image {
            text-align: center;
            
            @include media-breakpoint-up(sm) {
                text-align: end;
            }
        }

        a {
            &:hover {
                color: $olive-light;
            }
        }
    }

    .footer-copyright {
        padding: 1rem 0;
        font-size: $small-font-size;
        font-style: italic;

        .list {
            gap: .5rem 2rem;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                margin-bottom: 4rem;
            }

            .link {
                color: $black;
                text-decoration: none;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
